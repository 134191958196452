import PerfectScrollbar from "perfect-scrollbar";
import { assigned } from "../../utils/helper";
import { ThemePlugin, ThemePluginName } from "../ThemePlugin";

export class SidebarPlugin implements ThemePlugin {
    Name: ThemePluginName = 'sidebar';

    private menuScrollbar: PerfectScrollbar;
    private hasCompactMenu: boolean;
    private stackedMenu: any;

    init() {
        // Nur wenn es auch stacke dmenu elemente gibt
        if (document.querySelectorAll('#stacked-menu, .stacked-menu').length <= 0) {
            return;
        }

        this.hasCompactMenu = JSON.parse(localStorage.getItem('hasCompactMenu')) ?? false;
        // Wenn wir klein sind, setzen wir die Klasse direkt zu allererst, damit das Rendering das direkt weiß
        document.querySelector('.app')?.classList.toggle('has-compact-menu', this.hasCompactMenu);

        // DOM vorbereiten
        this.perpareAsideBackdrop();
        this.initAsideToggle();

        // Menü erstellen
        this.createAsideMenu();

        //Eventhandler
        this.handleAsideMenu();
    }

    //#region Aside Menu


    createAsideMenu() {
        this.menuScrollbar = new PerfectScrollbar('.aside-menu', {
            suppressScrollX: true
        });

        this.stackedMenu = new (<any>window).StackedMenu({
            compact: this.hasCompactMenu,
        });
        this.stackedMenu.selector.addEventListener('menu:open', () => this.updateMenuScrollbar());
        this.stackedMenu.selector.addEventListener('menu:close', () => this.updateMenuScrollbar());
        window.addEventListener('load', () => this.updateMenuScrollbar());
    }

    handleAsideMenu() {
        let bodyElement = document.body

        bodyElement.querySelector('[data-bs-toggle="aside-collapse"]')?.addEventListener('click', (event) => {
            event.preventDefault();
            this.collapseAsideMenu();
        });
        bodyElement.querySelector('[data-bs-toggle="aside-expand"]')?.addEventListener('click', (event) => {
            event.preventDefault();
            this.expandAsideMenu();
        });
        bodyElement.querySelector('[data-bs-toggle="aside-menu"]')?.addEventListener('click', (event) => {
            event.preventDefault();
            this.toggleAsideMenu();
        });

        document.querySelector('html').classList.remove('has-compact-menu');

        window.addEventListener('resize', () => {
            this.watchAside();
        });

        this.watchAside();
    }

    collapseAsideMenu() {
        if (assigned(this.stackedMenu)) {
            this.stackedMenu.compact(true).hoverable(true); // update aside mode

            this.hasCompactMenu = true; // as well as localStorage data
            localStorage.setItem('hasCompactMenu', 'true'); // update flag class

            document.querySelector('.app')?.classList.add('has-compact-menu');
        }

        this.watchAside();
    }

    expandAsideMenu() {
        if (assigned(this.stackedMenu)) {
            this.stackedMenu.compact(false).hoverable(false); // update aside mode

            this.hasCompactMenu = false; // as well as localStorage data
            localStorage.setItem('hasCompactMenu', 'false'); // update flag class

            document.querySelector('.app')?.classList.remove('has-compact-menu');
        }

        this.watchAside();
    }

    toggleAsideMenu() {
        if (this.hasCompactMenu == true) {
            this.expandAsideMenu();
        }
        else {
            this.collapseAsideMenu();
        }
    }

    updateMenuScrollbar() {
        // die Animation vom auf/zuklappen muss zuerst durch sein
        window.setTimeout(() => this.menuScrollbar.update(), 200);
    }
    //#endregion

    //#region Aside
    perpareAsideBackdrop() {
        let div = document.createElement('div');
        div.classList.add('aside-backdrop');

        document.querySelector('.app')?.appendChild(div);
    }

    watchAside() {
        let appElement = document.querySelector('.app');

        if (assigned(this.stackedMenu)) {
            if (!window.matchMedia('(min-width: 1200px)').matches || appElement?.classList.contains('has-fullwidth')) {
                this.stackedMenu.compact(false).hoverable(false);
                appElement?.classList.remove('has-compact-menu');
            } else {
                this.stackedMenu.compact(this.hasCompactMenu).hoverable(this.hasCompactMenu);
                appElement?.classList.toggle('has-compact-menu', this.hasCompactMenu);
            }
        }
    }

    initAsideToggle() {
        let triggerElement = document.querySelector('[data-bs-toggle="aside"]');

        triggerElement?.addEventListener('click', () => {
            let isShown = document.querySelector('.app-aside')?.classList.contains('show') ?? false;
            triggerElement.classList.toggle('active', !isShown);
            if (isShown) {
                this.hideAside();
            } else {
                this.showAside();
            };
        });
    }

    hideAside() {
        document.querySelector('.aside-backdrop')?.classList.remove('show');
        document.querySelector('.app-aside')?.classList.remove('show');
        document.querySelector('[data-bs-toggle="aside"]')?.classList.remove('active');
    }

    showAside() {
        let asideBackdropElement = document.querySelector('.aside-backdrop');
        asideBackdropElement?.classList.add('show');
        asideBackdropElement?.addEventListener('click', () => {
            this.hideAside();
        });

        document.querySelector('.app-aside').classList.add('show');
        document.querySelector('[data-bs-toggle="aside"]').classList.add('active');
    }
    //#endregion
}