import { assigned, onReady } from "../utils/helper";
import { BootstrapPlugin } from "./BootstrapPlugin";
import { ThemePlugin } from "./ThemePlugin";

export class ConSenseTheme {
    private plugins: Array<ThemePlugin>;
    private bootstrap: BootstrapPlugin;

    constructor(usedPlugins?: Array<ThemePlugin>) {
        this.plugins = usedPlugins ?? [];
        this.bootstrap = new BootstrapPlugin();

        onReady(() => this.doInit());
    }

    doInit() {
        // erst Bootstrap
        this.initBootstrap();

        // dann die Plugins
        this.initPlugins();

        // und ein paar eigene Theme Dinge
        this.initInputGroups();
    }

    initBootstrap() {
        this.bootstrap.init();
    }

    initPlugins() {
        this.plugins.forEach(plugin => plugin.init());
    }

    //#region Theme Themen
    initInputGroups() {
        // Bei Input Gruppen, also z. B. zusammengesetzten Elementen, setzen wir die Fokus Klasse auf das außenliegende Gruppenelement
        let eventHandler = (event: FocusEvent) => {
            if (event.target instanceof Node) {
                for (let target = event.target; assigned(target); target = target.parentNode) {
                    if (target instanceof Element && target.matches('.input-group:not(.input-group-alt) .form-control')) {
                        // Dann Code
                        const parent = (<Node>event.target).parentNode as Element; // currentNode entspricht jQuery this in Events -> https://stackoverflow.com/a/21667010/6244709
                        const hasInputGroup = parent?.classList?.contains('input-group') ?? false;
                        const hasFocus = event.type === 'focusin';

                        if (hasInputGroup) {
                            parent.classList.toggle('focus', hasFocus);
                        }
                        break;
                    }
                }
            }
        };

        document.addEventListener('focusin', event => eventHandler(event));
        document.addEventListener('focusout', event => eventHandler(event));
    }
    //#endregion
}