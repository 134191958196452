export function registerGlobalScrollUtils() {
    // Die werden aus Delphi heraus benötigt
    window[scrollToCS.name] = scrollToCS;
    window[getOffsetforScrollSpy.name] = getOffsetforScrollSpy;
}

function scrollToCS(section) {
    $("html, body").animate({
        scrollTop: $("#" + section).offset().top - 100
    }, 500);
}

function getOffsetforScrollSpy(target) {
    var defaultResult = 200;
    const firstLink = $('#' + target).find('[data-spyscrollid]')[0];
    if (typeof firstLink != 'undefined') {
        const firstTarget = $(firstLink).data('spyscrollid');
        defaultResult = $('#' + firstTarget).offset().top - 50;
    }
    return defaultResult;
}