import { assigned } from "../../utils/helper";
import { ThemePlugin, ThemePluginName } from "../ThemePlugin";

export class LookupPlugin implements ThemePlugin {
    Name: ThemePluginName = 'lookup';
    page: HTMLElement;
    sidebarToggleElement: HTMLElement;

    init() {
        this.page = document.querySelector('.page');
        this.sidebarToggleElement = document.querySelector('[data-bs-toggle="sidebar"]');

        // Nur, wenn es auch das Toggle gibt
        if (assigned(this.sidebarToggleElement)) {
            this.sidebarToggleElement.addEventListener('click', event => this.handleToggleElementClicked(event));

            this.addGlobalBackdropElement();
        }
    }

    addGlobalBackdropElement() {
        if (!this.pageHasSidebar()) {
            return;
        }

        let backdropElement = document.querySelector('sidebar-backdrop');
        if (!assigned(backdropElement)) {
            backdropElement = document.createElement('div');
            backdropElement.classList.add('sidebar-backdrop');
            this.page.insertBefore(backdropElement, this.page.firstChild);
        }
        // jetzt sollten wir einen Backdrop haben!
        backdropElement.addEventListener('click', () => this.hideSidebar());
    }

    pageHasSidebar(): boolean {
        return assigned(document.querySelector('.has-sidebar'));
    }

    isSidebarVisible(): boolean {
        return this.pageHasSidebar && assigned(document.querySelector('.has-sidebar-open'));
    }

    handleToggleElementClicked(event: MouseEvent) {
        event.preventDefault();

        this.toggleSidebar();
    }

    protected doToggleSidebar(showSidebar: boolean) {
        this.page.classList.toggle('has-sidebar-open', showSidebar);
    }

    showSidebar() {
        this.doToggleSidebar(true);
    }

    hideSidebar() {
        this.doToggleSidebar(false);
    }

    toggleSidebar() {
        if (this.isSidebarVisible()) {
            this.hideSidebar();
        }
        else {
            this.showSidebar();
        }
    }
}