import { MessageType, MsgNotify } from "../core/utils/msgnotify";
import { assigned, onReady } from "../utils/helper";
import { AsType } from "../utils/html";

export function tryRegisterFastSearch(): void {
    // wait until everything is loaded

    onReady(() => {
        let searchInput = AsType<HTMLInputElement>(document.querySelector('.top-bar-search input'), false); // wir haben eigenes Errorhandling wenns nicht gibt oder nicht geht

        // nicht jede Seite hat die Schnellsuche
        if (!assigned(searchInput)) {
            return;
        }

        searchInput.addEventListener('keyup', (event: KeyboardEvent) => {

            if (event.key == 'Enter') {
                handleFastSearch(searchInput);
            }
        });
    });
}

function handleFastSearch(searchinput: HTMLInputElement) {
    let idSearchFor = encodeURIComponent(searchinput.value);
    let idSearchType = -1;
    let idSiteOID = ''; //encodeURIComponent(searchinput.val());

    sendSearchRequest(idSearchFor, idSearchType, idSiteOID);
}

function sendSearchRequest(searchfor: string, searchtype: number, siteoid: string) {
    if (searchfor == '') {
        MsgNotify('Bitte geben Sie einen Suchbegriff ein!', MessageType.Warning);
    } else {
        let newURL = 'Search/Results';
        newURL += "?SearchFor=" + searchfor.replace(/\+/g, '%2B') + "&SearchType=" + searchtype; // UPS // geht das via URL Objekt nicht auch iwie eleganter?
        window.open(newURL, '_self');
    }
}