import PerfectScrollbar from "perfect-scrollbar";
import { ThemePlugin, ThemePluginName } from "../ThemePlugin";

export class PerfectScrollbarPlugin implements ThemePlugin {
    Name: ThemePluginName = 'perfect-scrollbar'
    init() {
        document.querySelectorAll('.perfect-scrollbar').forEach(item => {
            new PerfectScrollbar(item, {
                suppressScrollX: true
            })
        });
    }
}