import { ConsoleConstructor } from "console";
import { MessageType, MsgNotify } from "../core/utils/msgnotify";

//#region DebuggingConsole
class DebugConsole implements Console {
    browserConsole: Console;
    Console: ConsoleConstructor;

    constructor(originalConsole: Console) {
        this.browserConsole = originalConsole;

        this.Console = this.browserConsole.Console;
    }

    doDebugMessage(data: any[] | string, msgType: MessageType = MessageType.Info): void {
        if (typeof data === 'string') {
            MsgNotify(data, msgType);
        }
        else if (typeof data[0] === 'string') {
            this.doDebugMessage(data[0], msgType);
        }
    }

    assert(condition?: boolean, ...data: any[]): void {
        this.browserConsole.assert(condition, data);
    }

    clear(): void {
        this.browserConsole.clear();
    }

    count(label?: string): void {
        this.browserConsole.count();
    }

    countReset(label?: string): void {
        this.browserConsole.countReset();
    }

    debug(...data: any[]): void {
        this.doDebugMessage(data, MessageType.Dark);

        this.browserConsole.debug(data);
    }

    dir(item?: any, options?: any): void {
        this.browserConsole.dir(item, options);
    }

    dirxml(...data: any[]): void {
        this.browserConsole.dirxml(data);
    }

    error(...data: any[]): void {
        this.doDebugMessage(data, MessageType.Danger);

        this.browserConsole.error(data);
    }

    exception(message?: string, ...optionalParams: any[]): void {
        this.doDebugMessage(message, MessageType.Danger);

        this.browserConsole.error(message, optionalParams);
    }

    group(...data: any[]): void {
        this.browserConsole.group(data);
    }

    groupCollapsed(...data: any[]): void {
        this.browserConsole.groupCollapsed(data);
    }

    groupEnd(): void {
        this.browserConsole.groupEnd();
    }

    info(...data: any[]): void {
        this.doDebugMessage(data, MessageType.Info);

        this.browserConsole.info(data);
    }

    log(...data: any[]): void {
        this.doDebugMessage(data, MessageType.Light);

        this.browserConsole.log(data);
    }

    profile(label?: string): void {
        this.browserConsole.profile(label);
    }

    profileEnd(label?: string): void {
        this.browserConsole.profileEnd(label);
    }

    table(tabularData?: any, properties?: string[]): void {
        this.browserConsole.table(tabularData, properties);
    }

    time(label?: string): void {
        this.browserConsole.time(label);
    }

    timeEnd(label?: string): void {
        this.browserConsole.timeEnd(label);
    }

    timeLog(label?: string, ...data: any[]): void {
        this.browserConsole.timeLog(label, data);
    }

    timeStamp(label?: string): void {
        this.browserConsole.timeStamp(label);
    }

    trace(...data: any[]): void {
        this.doDebugMessage(data, MessageType.Dark);

        this.browserConsole.trace(data)
    }

    warn(...data: any[]): void {
        this.doDebugMessage(data, MessageType.Warning);

        this.browserConsole.warn(data);
    }
}
//#endregion

export function checkJSDebuggingActive() {
    // erstmal raus!
    return;
    // das wurde mal via Delphi gesetzt! Da wir es nicht genutzt haben, ist das erstmal rausgeflogen in Delphi
   // vielleicht kann man das noch via Cookie oder so in Zukunft setzen
    /*
    let CSJavascriptDebugmodus = window['CSJavascriptDebugmodus'];

    if (assigned(CSJavascriptDebugmodus) && CSJavascriptDebugmodus == true) {
        CsConsole('JS-Debugmodus aktiv', ConsoleMessageType.Information);
        MsgNotify('JS-Debugmodus aktiv', MessageType.Info);
        //Then redefine the old console
        let originalConsole = window.console;
        window.console = new DebugConsole(originalConsole);
    }*/
}