import shave from "shave";
import * as Helper from '../utils/helper';

export function InitShaveObserver(): void {
    Helper.onReady(() => {
        // Falls Shave-Elemente reinkommen
        new MutationObserver(handleMutation).observe(document.querySelector('body'), {
            attributes: false,
            childList: true,
            subtree: true,
        });

        // Shave-Elemente, die bereits zum Start da sind 
        document.querySelectorAll('.shave-text').forEach(element => {
            if (element instanceof HTMLElement) {
                shaveText(element);
            }
        })
    });
}

function handleMutation(mutations: Array<MutationRecord>, observer: MutationObserver): void {
    mutations.forEach(mutation => {
        if (mutation.type !== 'childList') {
            return;
        }

        mutation.addedNodes.forEach(node => {
            let iterateFunc = (node: Node) => {
                // Nur die HTMLElement Nodes können eine Komponente sein!
                if (node instanceof HTMLElement && node.classList.contains('shave-text')) {
                    shaveText(node);
                }

                node.childNodes.forEach(child => {
                    iterateFunc(child);
                });
            }

            iterateFunc(node);
        });
    });
}

export function shaveText(element: HTMLElement): void {
    element.classList.forEach(name => {
        if (name.includes('shave-text-')) {
            let elCSS = getComputedStyle(element, null);
            let height = parseFloat(elCSS.fontSize) > parseFloat(elCSS.lineHeight) ? parseFloat(elCSS.fontSize) : parseFloat(elCSS.lineHeight);
            let lines = parseInt(name.replace('shave-text-', ''));
            // default spaces true sorgt dafür, dass nur an Leerzeichen abgeschitten wird -> lange Wörter werden nicht abgeschnitten
            shave(element, height * lines, { spaces: false, character: '…' }); // Horizontal ellipsis -> https://util.unicode.org/UnicodeJsps/character.jsp?a=2026
        }
    });
}