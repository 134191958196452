import bootstrap from "bootstrap";
import { ThemePlugin, ThemePluginName } from "./ThemePlugin";

export class BootstrapPlugin implements ThemePlugin {
    private static TOOLTIP_SELECTOR = '[data-bs-toggle="tooltip"]';
    private static POPOVER_SELECTOR = '[data-bs-toggle="popover"]';

    Name: ThemePluginName = "Bootstrap";
    private observer: MutationObserver;

    init() {
        document.querySelectorAll(BootstrapPlugin.TOOLTIP_SELECTOR).forEach(item => {
            this.initTooltip(item);
        });

        document.querySelectorAll(BootstrapPlugin.POPOVER_SELECTOR).forEach(item => {
            this.initPopover(item);
        });

        this.observer = new MutationObserver((mutationList, observer) => this.handleDOMMutations(mutationList, observer));
        this.observer.observe(document, {
            childList: true,
            subtree: true,
        });
    }

    initTooltip(element: Element) {
        new bootstrap.Tooltip(element);
    }

    initPopover(element: Element) {
        new bootstrap.Popover(element);
    }

    protected handleDOMMutations(mutationList: Array<MutationRecord>, observer: MutationObserver) {
        mutationList.forEach((mutation: MutationRecord) => {
            if (mutation.type === 'childList') {
                mutation.addedNodes.forEach(node => {
                    if (node instanceof Element) {
                        // haben wir selber ein Tooltip?                     
                        if (node.matches(BootstrapPlugin.TOOLTIP_SELECTOR)) {
                            this.initTooltip(node);
                        }
                        // Haben unsere children ein Tooltip?
                        node.querySelectorAll(BootstrapPlugin.TOOLTIP_SELECTOR).forEach(element => {
                            this.initTooltip(element);
                        });
                    }
                });
            }
        });
    }
}
