import Mousetrap, { ExtendedKeyboardEvent } from 'mousetrap';
import printJS from 'print-js';
import { checkJSDebuggingActive } from './app/consense.debugger';
import { tryRegisterFastSearch } from './app/consense.fastsearch';
import { registerConSenseKeyboardEvents, registerConSenseMouseEvents } from './app/consense.keyboard';
import { registerGlobalScrollUtils } from './app/consense.scrollutils';
import { InitShaveObserver } from './app/shaveText';
import { LookupPlugin } from './theme/plugins/LookupPlugin';
import { PerfectScrollbarPlugin } from './theme/plugins/PerfectScrollbarPlugin';
import { SidebarPlugin } from './theme/plugins/SidebarPlugin';
import { ConSenseTheme } from './theme/Theme';
import { assigned } from './utils/helper';

// Externes JS integrieren, dass wir immer brauchen
require('regenerator-runtime');

// Theme "initialisieren"
let theme = new ConSenseTheme([
    new SidebarPlugin(),
    new PerfectScrollbarPlugin(),    
    new LookupPlugin(),
]);

// Keyboardhandler registrieren
registerConSenseKeyboardEvents();
registerConSenseMouseEvents();

// Schnellsuche initialisieren
tryRegisterFastSearch();

// ScrollUtils
registerGlobalScrollUtils();

// Special Debug Mode
checkJSDebuggingActive();

// Mousetrap für Suche
new Mousetrap(document.documentElement).bind('ctrl+f', function (event: ExtendedKeyboardEvent, keys: string) {
    if ($('.modal:visible').length > 0)
        return;

    let searchInput = document.getElementById('top_bar_search_input');

    // nicht jede Seite hat die Searchbar
    if (assigned(searchInput)) {
        // wenn Searchbar schon den Fokus hat, dann den Standard verwenden (Browsersuche)
        if (document.activeElement !== searchInput) {
            event.preventDefault();
            searchInput.focus();
        }
    }
});

InitShaveObserver();

// PrintJS Helper -> Für "URL-Zugriff" global registrieren
window[printJS.name] = printJS;